<template>
  <v-container>
    <v-row justify="center" class="mr-2 ml-2">
      <v-col class="text-center" lg="3">
        <v-btn @click="panel.length == 0 ? all() : none()" color="primary">
          {{ panel.length == 0 ? $t('expand') : $t('collapse') }}
        </v-btn>
      </v-col>
      <v-col class="d-flex justify-center" lg="3">
        <v-select
          :style="{ fontSize: '15px', maxWidth: '110px' }"
          height="20"
          v-model="lang"
          :items="languages"
          item-text="name"
          item-value="locale"
          @change="changeLocale()"
          outlined
          dense
          hide-details="auto"
          single-line
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row id="keyboard" justify="center">
      <v-col cols="auto" lg="6" md="4" sm="4">
        <v-expansion-panels focusable v-model="panel" multiple>
          <v-expansion-panel
            class="section-panel"
            v-for="section in sections"
            :key="section.sectionIndex"
          >
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon class="icon">$expand</v-icon>
              </template>
              <h3>{{ section[lang] }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list subheader two-line>
                <v-list-item
                  v-for="document in section.docs"
                  :key="document['document-name']"
                  :href="document.url[lang] || document.url.en"
                  target="_blank"
                >
                  <v-list-item-avatar>
                    <v-icon
                      class="amber"
                      dark
                      v-text="
                        document.secure ? 'mdi-lock' : 'mdi-gesture-tap-button'
                      "
                    ></v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      style="white-space: normal;"
                      v-text="document.name[lang]"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <GoBackButton />
  </v-container>
</template>
<script>
import GoBackButton from './GoBackButton'
export default {
  components: {
    GoBackButton
  },
  methods: {
    all() {
      this.panel = Object.keys(this.sections).map(
        k => this.sections[k].sectionIndex
      )
    },
    none() {
      this.panel = []
    },
    changeLocale() {
      this.buildSection()

      if (this.lang === 'en' || this.lang === 'es' || this.lang === 'de') {
        this.$i18n.locale = 'en'
        this.$store.state.selectedLanguage = 'eng'
      } else if (this.lang === 'hi') {
        this.$i18n.locale = 'hi'
        this.$store.state.selectedLanguage = 'hindi'
      } else if (this.lang === 'gu') {
        this.$i18n.locale = 'gu'
        this.$store.state.selectedLanguage = 'guj'
      }
    },
    buildSection() {
      this.sections = {}
      let sectionIndex = 0
      this.$store.state.studyMaterial
        .filter(doc => {
          if (this.lang !== 'en' || this.lang !== 'hi' || this.lang !== 'gu') {
            if (doc[this.lang] !== '') {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        })
        .forEach(doc => {
          if (this.sections[doc.section]) {
            this.sections[doc.section].docs.push({
              name: doc.doc,
              url: doc.url
            })
          } else {
            this.sections[doc.section] = {
              sectionIndex: sectionIndex,
              en: doc.en,
              hi: doc.hi,
              de: doc.de,
              es: doc.es,
              gu: doc.gu,
              docs: [{ name: doc.doc, url: doc.url }]
            }
            sectionIndex++
          }
        })
      this.panel = [0]
    }
  },
  data: () => ({
    panel: [0],
    sections: [],
    lang: 'en',
    languages: [
      { name: 'English', locale: 'en', value: 'eng' },
      { name: 'हिन्दी', locale: 'hi', value: 'hindi' },
      { name: 'Español/Português', locale: 'es', value: 'es' },
      { name: 'Deutsch', locale: 'de', value: 'de' },
      { name: 'ગુજરાતી', locale: 'gu', value: 'gu' }
    ]
  }),
  mounted() {
    this.lang = this.$i18n.locale
    if (this.lang === 'gu') this.lang = 'en'
    this.buildSection()
  }
}
</script>
<style scoped>
/* D. keyboard */
#keyboard {
  margin-left: 2px;
  margin-right: 2px;
}
.icon {
  color: var(--v-primary-base) !important;
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: white;
  margin: 0.25em;
  color: var(--v-primary-base) !important;
}
.v-expansion-panel {
  background-color: white;
}
.section-panel {
  border: 2px solid var(--v-primary-base);
}
</style>
